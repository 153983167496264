import React, { useState, useEffect } from 'react';
import { Button, TextField, Grid, Card, CardContent, Typography, Box } from '@mui/material';
import axios from 'axios';
import instance from '../constant/instance';

const FeatureForm = () => {
    const [name, setName] = useState('');
    const [data, setData] = useState([{ title: '', description: '' }]);
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    const [features, setFeatures] = useState([]);
    const [selectedFeature, setSelectedFeature] = useState(null);

    const fetchFeatures = async () => {
        try {
            const response = await instance.get('/features');
            setFeatures(response.data.features);
        } catch (error) {
            console.error('Error fetching features', error);
        }
    };

    // Fetch existing features
    useEffect(() => {
       
        fetchFeatures();
    }, []);

    // Handle multiple image selection and preview
    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        setImages(files);

        // Generate image preview URLs
        const previews = files.map((file) => URL.createObjectURL(file));
        setImagePreviews(previews);
    };

    // Handle data change (for title and description fields)
    const handleDataChange = (index, field, value) => {
        const updatedData = [...data];
        updatedData[index][field] = value;
        setData(updatedData);
    };

    // Add a new field in data array (add a new title and description)
    const addDataField = () => {
        setData([...data, { title: '', description: '' }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('name', name);
        formData.append('data', JSON.stringify(data));

        images.forEach((image) => {
            formData.append('images', image);
        });

        try {
                await instance.post('/features', formData);
            fetchFeatures()
            
        } catch (error) {
            console.error('Error submitting feature', error);
        }
    };

    const handleFeatureSelect = (feature) => {
        setSelectedFeature(feature);
        setName(feature.name);
        setData(feature.data);
        setExistingImages(feature.images || []);  // Show existing images
    };

    const removeExistingImage = (image) => {
        setExistingImages(existingImages.filter(img => img !== image));  // Remove the selected image from the existingImages array
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="h2">
                            {selectedFeature ? 'Update Feature' : 'Create New Feature'}
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <TextField
                                fullWidth
                                label="Feature Name"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                margin="normal"
                                required
                            />

                            {data.map((item, index) => (
                                <Box key={index} mb={2}>
                                    <TextField
                                        fullWidth
                                        label={`Title ${index + 1} (Optional)`}
                                        value={item.title}
                                        onChange={(e) =>
                                            handleDataChange(index, 'title', e.target.value)
                                        }
                                        margin="normal"
                                    />
                                    <TextField
                                        fullWidth
                                        label={`Description ${index + 1} (Optional)`}
                                        value={item.description}
                                        onChange={(e) =>
                                            handleDataChange(index, 'description', e.target.value)
                                        }
                                        margin="normal"
                                    />
                                </Box>
                            ))}

                            <Button onClick={addDataField} variant="outlined" fullWidth>
                                Add More Data (Title & Description)
                            </Button>

                            {/* Display existing images */}
                            <Grid container spacing={2} style={{ marginTop: '20px' }}>
                                {existingImages.map((image, index) => (
                                    <Grid item xs={4} key={index}>
                                        <Box position="relative">
                                            <img
                                                src={image}
                                                alt={`Existing Image ${index + 1}`}
                                                style={{ width: '100%' }}
                                            />
                                            <Button
                                                variant="outlined"
                                                color="secondary"
                                                onClick={() => removeExistingImage(image)}
                                                style={{ position: 'absolute', top: 5, right: 5 }}
                                            >
                                                Remove
                                            </Button>
                                        </Box>
                                    </Grid>
                                ))}
                            </Grid>

                            {/* File Upload for new images */}
                            <Button
                                variant="contained"
                                component="label"
                                fullWidth
                                style={{ margin: '20px 0' }}
                            >
                                Upload New Images
                                <input
                                    type="file"
                                    hidden
                                    multiple
                                    onChange={handleImageChange}
                                />
                            </Button>

                            {/* New Image Previews */}
                            <Grid container spacing={2} >
                                {imagePreviews.map((preview, index) => (
                                    <Grid item xs={4} key={index}>
                                        <img
                                            src={preview}
                                            alt={`Preview ${index + 1}`}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>

                            <Button type="submit" variant="contained" color="primary" fullWidth>
                                {selectedFeature ? 'Update Feature' : 'Create Feature'}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Grid>

            {/* Display existing features */}
            <Grid item xs={12} sm={6}>
                <Typography variant="h5" component="h2">
                    Existing Features
                </Typography>
                {features.map((feature) => (
                    <Card key={feature._id} style={{ marginBottom: '20px' }}>
                        <CardContent>
                            <Typography variant="h6">{feature.name}</Typography>
                            <Typography style={{ marginBottom : "10px" , marginTop : "10px"}}>
                                {feature.data.length > 0
                                    ? feature.data.map((d) => `${d.title || 'N/A'}: ${d.description || 'N/A'}`).join(', ')
                                    : 'No Data'}
                            </Typography>
                            <Grid container spacing={2}>
                                {(features?.images?.length !== 0)  && feature?.images.map((preview, index) => (
                                    <Grid item xs={4} key={index}>
                                        <img
                                            src={preview}
                                            alt={`Preview ${index + 1}`}
                                            style={{ width: '100%' }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => handleFeatureSelect(feature)}
                                style={{ marginTop: '10px' }}
                            >
                                Edit Feature
                            </Button>
                        </CardContent>
                    </Card>
                ))}
            </Grid>
        </Grid>
    );
};

export default FeatureForm;
