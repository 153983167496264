import React, { useState , useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import axios from 'axios'
import instance from '../constant/instance';
// Sample order data
const ordersData = [
  { id: '1', orderId: 'cs_test_1', status: 'pending', totalItems: 2, customerName: 'John Doe', totalAmount: '$120' },
  { id: '2', orderId: 'cs_test_2', status: 'shipped', totalItems: 1, customerName: 'Jane Doe', totalAmount: '$70' },
];

const Orders= () => {
  const [orders, setOrders] = useState([]);
  const [statusEnum , setStatusEnum] = useState([])
  const getAllOrders = async() =>{
      try{
        const { data }  = await instance.get('/order')
        setOrders([...data.data]) 
        setStatusEnum(data.statusEnum)
      } catch(err) {
         console.log(err)
      }
  }
  const handleStatusChange = async(id, newStatus) => {
     try{
        await instance.put(`/order/${id}`,{ status : newStatus })
        getAllOrders()
     }
     catch(err) {
        console.log(err)
     }
  };

  useEffect(() =>{
      getAllOrders()
  },[])

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Order ID</TableCell>
            <TableCell>Customer Name</TableCell>
            <TableCell>Total Items</TableCell>
            <TableCell>Total Amount</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(orders.length !== 0) && orders?.map((order) => (
            <TableRow key={order?.id}>
              <TableCell>{order?.orderId}</TableCell>
              <TableCell>{order?.shipping_details[0]?.name}</TableCell>
              <TableCell>{order?.totalItems}</TableCell>
              <TableCell>{order?.totalAmount}</TableCell>
              <TableCell>
                <Select
                  value={order?.status}
                  onChange={(e) => handleStatusChange(order?._id, e.target.value)}
                >
                  {statusEnum.map((status) =>(
                  <MenuItem value={status}>{status}</MenuItem>
                ))}
                </Select>
              </TableCell>
              <TableCell>
                <Button variant="contained" component={Link} to={`/orders/${order._id}`}>
                  More Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};


export default Orders